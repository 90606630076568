import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import { useRouteItem } from '@/router/useRouterTools';
import { useAppStore } from '@store/appStore';
import i18n from '@/utils/i18n';
const $t = i18n.global.t;
import { showPop, setShowPop } from '@cps/Wallets/useWallet';

/**
 * 处理写的hook
 * @param func 回调函数
 * @returns
 * eg:
 *  const [forgive, loadForgive] = useWrite(async () => {
 *    const resp = await boxObj.directDepositIt(1);
 *  });
 */
export function useWrite(func) {
  const route = useRouteItem();
  const appStore = useAppStore();
  const loading = ref(false);
  async function help(...params) {
    if (loading.value) return;

    // 没有小狐狸插件，则跳去下载
    if (!window.ethereum) {
      window.open('https://metamask.io/download/');
      return;
    }

    // 没有连接钱包
    if (!appStore.defaultAccount) {
      ElMessage.error($t('msg.22'));
      setShowPop(true);
      return;
    }

    // 链不对
    if (!appStore.rightLink) {
      // debugger;
      // 提示
      ElMessage.error($t('msg.31'));

      // 弹窗
      loading.value = true;
      await appStore.chainChanged(route.meta.needChains[0]);

      loading.value = false;
      return;
    }

    loading.value = true;
    const resp = await func(...params).catch(err => {
      console.log(err);
    });
    loading.value = false;
    if (resp?.status === false) {
      // 返回报错信息
      return resp?.message;
    }
    // 请求成功，返回数据
    return resp;
  }

  return [help, loading];
}

/**
 * 处理读的hook
 * @param func 回调函数
 * @returns
 * tips: 如果是在 script 中使用返回值:datas，记得要加上 .value
 * 
 * eg:
 *  const [checkInfo, { datas: myBalan, loading }] = useRead(async () => {
      const p1 = lpContract.getBalance();
      const p2 = lpContract.getBalance();
      const result = await Promise.all([p1, p2]);
      return result; // 此时 myBalan 会得到该返回值
    }); 
 * 
 */
export function useRead(func) {
  const datas = ref(null); // 返回值
  const loading = ref(false); // 加载状态

  async function help(...params) {
    // if (loading.value) return; // read不同于write,允许频繁调用

    loading.value = true;
    const resp = await func(...params);

    if (resp?.status === false) {
      // 返回报错信息
      datas.value = resp?.message;
      loading.value = false;
      return datas.message;
    } else {
      // 请求成功，返回数据
      datas.value = resp;
      loading.value = false;
      return resp;
    }
  }

  return [help, { datas, loading }];
}
